<template>
         <div class="page">
                    <div class="row">
                        <div style="width:99%">
                            <div class="header" id="header" style="width:100%;height:100px;">
                                <div style="width:11%;float:left;">
                                    <img src="../assets/print_logo.jpg" style="width:100px;height:100px;"/>
                                </div>
                                <div style="width:89%;float:right;">
                                    <p style="font-size:28px;font-family:Times New Roman;text-align:center;vertical-align:text-top;"><b>Draco	Natural	Products,	Inc. </b></p>
                                    <p style="font-size:28px;font-family:Times New Roman;text-align:center;vertical-align:text-top;"><b><u>CERTIFICATE OF ANALYSIS</u></b> </p>
                                </div>
                            </div>

                            <!-- Product info -->
                            <table style="width:99%;">
                                <tr>
                                    <td style="width:25%"><b>Product Name</b></td>
                                    <td style="width:75%"><span t-field="o.name"/>{{printModel.params.productName}}</td>
                                </tr>
                                <tr>
                                    <td style="width:25%"><b>Product Type</b></td>
                                    <td style="width:75%"><span t-field="o.name"/>{{printModel.params.productType}}</td>
                                </tr>
                                <tr>
                                    <td style="width:25%"><b>Item Code</b></td>
                                    <td style="width:75%"><span t-field="o.default_code"/>{{printModel.params.itemCode}}</td>
                                </tr>
                                <tr>
                                    <td style="width:25%"><b>Product Description</b></td>
                                    <td style="width:75%"><span t-field="o.description"/>{{printModel.params.productDescription}}</td>
                                </tr>
                                <tr  style="height:auto;" v-if="printModel.productTypeVisible.shxto || printModel.productTypeVisible.ehxto">
                                    <td style="width:25%"><b>Processing</b></td>
                                    <td style="width:75%"><span t-field="o.description" :key="i" v-for="(item,i) in printModel.processingArr"> {{item}}</span>
                                   </td>
                                </tr>
                            </table>


                            <table style="width:99%">
                                <thead>
                                    <tr>
                                        <th style="width:30%"><div style="font-size:18px;"><b><u>General Specification</u></b></div></th>
                                        <th style="width:70%"/>
                                    </tr>
                                </thead>
                                <tbody v-if="!printModel.productTypeVisible.normal">

                                    <tr>
                                        <td style="width:30%"><b>Common Name</b></td>
                                        <td style="width:70%"><span t-field="o.product_grade" :key="i" v-for="(tag,i) in printModel.commonNameArr">{{tag}}</span></td>
                                    </tr>

                                    <tr>
                                        <td style="width:30%"><b>INCI Name</b></td>
                                        <td style="width:70%"><span t-field="o.inci_name" :key="i" v-for="(tag,i) in printModel.inclNameArr">{{tag}}</span></td>
                                    </tr>

                                    <tr>
                                        <td style="width:30%"><b>Botanical Name</b></td>
                                        <td style="width:70%"><span t-field="o.apperance" :key="i" v-for="(tag,i) in printModel.botanicalNameArr">{{tag}}</span></td>
                                    </tr>
                                    
                                     <tr v-if="printModel.majorConstituentsInNativeHerbsArr.length>0">
                                        <td style="width:30%"><b>Major Constituents in Native Herbs</b></td>
                                        <td style="width:70%"><span t-field="o.product_grade" :key="i" v-for="(tag,i) in printModel.majorConstituentsInNativeHerbsArr">{{tag}}</span></td>
                                    </tr>

                                    <tr>
                                        <td style="width:30%"><b>Part Used</b></td>
                                        <td style="width:70%"><span t-field="o.apperance" :key="i" v-for="(tag,i) in printModel.partUsedArr">{{tag}}</span></td>
                                    </tr>

                                    <tr v-if="printModel.productTypeVisible.shxto">
                                        <td style="width:30%"><b>Country of Origin</b></td>
                                        <td style="width:70%"><span t-field="o.apperance" >{{printModel.params.countryOfOrigi}}</span></td>
                                    </tr>

                                    <tr v-if="printModel.productTypeVisible.shxto || printModel.productTypeVisible.ehxto">
                                        <td style="width:30%"><b>Appearance</b></td>
                                        <td style="width:70%"><span t-field="o.apperance">{{printModel.params.appearance}}</span></td>
                                    </tr>

                                    <tr v-if="printModel.productTypeVisible.shxto">
                                        <td style="width:30%"><b>Solubility</b></td>
                                        <td style="width:70%"><span t-field="o.apperance">{{printModel.params.solubility}}</span></td>
                                    </tr>

                                    <tr>
                                        <td style="width:30%"><b>Color</b></td>
                                        <td style="width:70%"><span t-field="o.apperance">{{printModel.params.color}}</span></td>
                                    </tr>
                                    <tr>
                                        <td style="width:30%"><b>Order</b></td>
                                        <td style="width:70%"><span t-field="o.apperance">{{printModel.params.odor}}</span></td>
                                    </tr>
                                    <tr>
                                        <td style="width:30%"><b>Taste</b></td>
                                        <td style="width:70%"><span t-field="o.apperance">{{printModel.params.taste}}</span></td>
                                    </tr>
                                    <tr>
                                        <td style="width:30%"><b>Carrier Solvent</b></td>
                                        <td style="width:70%"><span t-field="o.apperance">{{printModel.params.carrierSolvent}}</span></td>
                                    </tr>
                                    <tr v-if="printModel.productTypeVisible.liquidExt">
                                        <td style="width:30%"><b>Carrier Solvent</b></td>
                                        <td style="width:70%"><span t-field="o.apperance" :key="i" v-for="(tag,i) in preservativeArr">{{tag}}</span></td>
                                    </tr>
                                    <tr v-if="printModel.productTypeVisible.liquidExt">
                                        <td style="width:30%"><b>Extraction Process</b></td>
                                        <td style="width:70%"><span t-field="o.apperance">Controlled-temperature water extraction process, spray dried</span></td>
                                    </tr>
                                    <tr v-if="printModel.productTypeVisible.liquidExt">
                                        <td style="width:30%"><b>Solvent for Extraction</b></td>
                                        <td style="width:70%"><span t-field="o.apperance">{{printModel.params.botanicalSolventForExtraction}}</span></td>
                                    </tr>
                                    <tr v-if="printModel.productTypeVisible.normal">
                                        <td style="width:30%"><b>Ingredients</b></td>
                                        <td style="width:70%"><span t-field="o.apperance" :key="i" v-for="(tag,i) in printModel.ingredientsArr">{{tag}}</span></td>
                                    </tr>
                                    <tr v-if="printModel.params.productType == 'PCG'">
                                        <td style="width:30%"><b>Product grade</b></td>
                                        <td style="width:70%"><span t-field="o.apperance" >Personal Care Grade</span></td>
                                    </tr>

                                    <tr>
                                        <td style="width:30%"><b>Common/Other Name</b></td>
                                        <td style="width:70%"><span t-field="o.apperance" :key="i" v-for="(tag,i) in printModel.commonOtherNameArr">{{tag}}</span></td>
                                    </tr>

                                     <tr>
                                        <td style="width:30%"><b>Extraction Ratio</b></td>
                                        <td style="width:70%"><span t-field="o.apperance" >{{printModel.params.extractionRatio}}</span></td>
                                    </tr>

                                    <tr>
                                        <td style="width:30%"><b>Important Constituents In Plant</b></td>
                                        <td style="width:70%"><span t-field="o.apperance" >{{printModel.params.importantConstitueInPlant}}</span></td>
                                    </tr>

                                    <tr>
                                        <td style="width:30%"><b>Homogeneity</b></td>
                                        <td style="width:70%"><span t-field="o.apperance" >Completely homogenous</span></td>
                                    </tr>

                                    <tr>
                                        <td style="width:30%"><b>Extraction Process</b></td>
                                        <td style="width:70%"><span t-field="o.apperance" >Controlled-temperature water extraction process, spray dried</span></td>
                                    </tr>
                                    
                                    <tr>
                                        <td style="width:30%"><b>Solvent for Extraction</b></td>
                                        <td style="width:70%"><span t-field="o.apperance" >{{printModel.params.solventForExtracti}}</span></td>
                                    </tr>

                                    <tr>
                                        <td style="width:30%"><b>Carrier</b></td>
                                        <td style="width:70%"><span t-field="o.apperance" >{{printModel.params.carrier}}</span></td>
                                    </tr>

                                    <tr>
                                        <td style="width:30%"><b>Certification</b></td>
                                        <td style="width:70%"><span t-field="o.apperance" >{{printModel.params.certification}}</span></td>
                                    </tr>

                                </tbody>

                            </table>

                            <!-- Analysis -->
                            <table style="width:99%">
                                <thead>
                                    <tr>
                                        <th style="width:30%"><h4><b>Analysis</b></h4></th>
                                        <th style="width:35%">Limit</th>
                                        <th style="width:35%">Test Method</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr v-for="(item,index) in printModel.shTestArr" :key="index">
                                        <td style="width:30%"><b>Acid Value</b></td>
                                        <td style="width:35%">{{item.testLimitAcidValue}}</td>
                                        <td style="width:35%">{{item.testMethodAcidValue}}</td>
                                    </tr>

                                    <tr v-for="(item,index) in printModel.shTestArr" :key="index">
                                        <td style="width:30%"><b>Peroxide Value</b></td>
                                        <td style="width:35%">{{item.testLimitPeroxideValue}}</td>
                                        <td style="width:35%">{{item.testMethodPeroxideValue}}</td>
                                    </tr>

                                    <tr v-for="(item,index) in printModel.shTestArr" :key="index">
                                        <td style="width:30%"><b>Saponification Value</b></td>
                                        <td style="width:35%">{{item.testLimitSaponificationValue}}</td>
                                        <td style="width:35%">{{item.testMethodSaponificationValue}}</td>
                                    </tr>

                                    <tr v-for="(item,index) in printModel.shTestArr" :key="index">
                                        <td style="width:30%"><b>Unsaponifiable Matter</b></td>
                                        <td style="width:35%">{{item.testLimitUnsaponifiableMaster}}</td>
                                        <td style="width:35%">{{item.testMethodUnsaponifiableMaster}}</td>
                                    </tr>

                                    <tr v-for="(item,index) in printModel.shTestArr" :key="index">
                                        <td style="width:30%"><b>Moisture & Volatiles</b></td>
                                        <td style="width:35%">{{item.testLimitMoistureVolatiles}}</td>
                                        <td style="width:35%">{{item.testMethodMoistureVolatiles}}</td>
                                    </tr>

                                    <tr v-for="(item,index) in printModel.shTestArr" :key="index">
                                        <td style="width:30%"><b>Lead</b></td>
                                        <td style="width:35%">{{item.testLimitLead}}</td>
                                        <td style="width:35%">{{item.testMethodLead}}</td>
                                    </tr>

                                    <tr v-for="(item,index) in printModel.shTestArr" :key="index">
                                        <td style="width:30%"><b>Arsenic</b></td>
                                        <td style="width:35%">{{item.testLimitArsenic}}</td>
                                        <td style="width:35%">{{item.testMethodArsenic}}</td>
                                    </tr>

                                    <tr v-for="(item,index) in printModel.shTestArr" :key="index">
                                        <td style="width:30%"><b>Cadmium</b></td>
                                        <td style="width:35%">{{item.testLimitCadmium}}</td>
                                        <td style="width:35%">{{item.testMethodCadmium}}</td>
                                    </tr>

                                    <tr v-for="(item,index) in printModel.shTestArr" :key="index">
                                        <td style="width:30%"><b>Mercury</b></td>
                                        <td style="width:35%">{{item.testLimitMercury}}</td>
                                        <td style="width:35%">{{item.testMethodMercury}}</td>
                                    </tr>

                                    <tr v-for="(item,index) in printModel.shTestArr" :key="index">
                                        <td style="width:30%"><b>Pesticides</b></td>
                                        <td style="width:35%">{{item.testLimitPesticides}}</td>
                                        <td style="width:35%">{{item.testMethodPesticides}}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <!-- Microbiological Tests -->

                            <table style="width:99%">
                                <thead>
                                    <tr>
                                        <th style="width:30%"><h4><b>Fatty Acid Composition</b></h4></th>
                                        <th style="width:35%">Limit</th>
                                        <th style="width:35%">Test Method</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr v-for="(item,index) in printModel.fattyAcidCompositionArr" :key="index">
                                        <td style="width:30%"><b>C16:0 Palmitic</b></td>
                                        <td style="width:35%"><span t-field="o.total_plate"/>{{item.fattryLimitPalmitica}}</td>
                                        <td style="width:35%"><span t-field="o.total_plate_test"/>{{item.fattryMethodPalmitica}}</td>
                                    </tr>

                                    <tr v-for="(item,index) in printModel.fattyAcidCompositionArr" :key="index">
                                        <td style="width:30%"><b>C16:1 Palmitoleic</b></td>
                                        <td style="width:35%"><span t-field="o.yeast_mold"/>{{item.fattryLimitPalmitoleic}}</td>
                                        <td style="width:35%"><span t-field="o.yeast_mold_test"/>{{item.fattryMethodPalmitoleic}}</td>
                                    </tr>

                                   <tr v-for="(item,index) in printModel.fattyAcidCompositionArr" :key="index">
                                        <td style="width:30%"><b>C16:1 Palmitoleic</b></td>
                                        <td style="width:35%"><span t-field="o.yeast_mold"/>{{item.fattryLimitPalmitoleic}}</td>
                                        <td style="width:35%"><span t-field="o.yeast_mold_test"/>{{item.fattryMethodPalmitoleic}}</td>
                                    </tr>

                                    <tr v-for="(item,index) in printModel.fattyAcidCompositionArr" :key="index">
                                        <td style="width:30%"><b>C18:0 Stearic</b></td>
                                        <td style="width:35%"><span t-field="o.yeast_mold"/>{{item.fattryLimitStearic}}</td>
                                        <td style="width:35%"><span t-field="o.yeast_mold_test"/>{{item.fattryMethodStearic}}</td>
                                    </tr>

                                    <tr v-for="(item,index) in printModel.fattyAcidCompositionArr" :key="index">
                                        <td style="width:30%"><b>C18:1 (Oleic + Vaccenic)</b></td>
                                        <td style="width:35%"><span t-field="o.yeast_mold"/>{{item.fattryLimitOleicVaccenic}}</td>
                                        <td style="width:35%"><span t-field="o.yeast_mold_test"/>{{item.fattryMethodOleicVaccenic}}</td>
                                    </tr>

                                    <tr v-for="(item,index) in printModel.fattyAcidCompositionArr" :key="index">
                                        <td style="width:30%"><b>C18:2 Linoleic</b></td>
                                        <td style="width:35%"><span t-field="o.yeast_mold"/>{{item.fattryLimitLinoleic}}</td>
                                        <td style="width:35%"><span t-field="o.yeast_mold_test"/>{{item.fattryMethodLinoleic}}</td>
                                    </tr>

                                    <tr v-for="(item,index) in printModel.fattyAcidCompositionArr" :key="index">
                                        <td style="width:30%"><b>C18:3 α-Linolenic</b></td>
                                        <td style="width:35%"><span t-field="o.yeast_mold"/>{{item.fattryLimitALinolenic}}</td>
                                        <td style="width:35%"><span t-field="o.yeast_mold_test"/>{{item.fattryMethodALinolenic}}</td>
                                    </tr>

                                    <tr v-for="(item,index) in printModel.fattyAcidCompositionArr" :key="index">
                                        <td style="width:30%"><b>Others</b></td>
                                        <td style="width:35%"><span t-field="o.yeast_mold"/>{{item.fatterLimitOthers}}</td>
                                        <td style="width:35%"><span t-field="o.yeast_mold_test"/>{{item.fatterMethodOthers}}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <table style="width:99%">
                                <thead>
                                    <tr>
                                        <th style="width:30%;font-size:18px"><b><u>Other Notes</u></b></th>
                                        <th style="width:70%"/>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style="width:30%"><b>Shelf Life</b></td>
                                        <td style="width70%"><span t-field="o.shelf_life"/></td>
                                    </tr>

                                    <tr>
                                        <td style="width:30%"><b>Storage</b></td>
                                        <td style="width70%"><span t-field="o.storage"/></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="footer" id="footer" style="font-size:11px;font-family:Times New Roman;margin-top:10px;">
                                <div><span><i>*Product was manufactured in a facility that also processes: other herbs, fruits and vegetables</i></span>.							Rev1.	03/14 by JC</div>
                                <div><b><u>Disclaimer: The test results listed are accurate to the best of our knowledge based upon our lab instruments and test methods. These results should not be used as a final determination for use in a finished product. According to GMP regulations, the specifications, test methods, and test results must be independently qualified by the purchasing party. The appropriate use of this product is the sole responsibility of the user or the purchasing party. Draco only guarantees the product purchased for the product specifications listed on the certificate of analysis and will only refund the product if it does not meet this specification and is still resalable. Draco will not refund the purchase if the product has been damaged or has been blended with other ingredients by the purchaser and is not responsible for costs of other ingredients the product was blended with. It is the responsibility of the purchaser to test extracts individually or in small pilot pre-blends to confirm that it meets the quality specifications before it is used.</u></b></div>
                                <div style="text-align:center;">539 Parrott Street San Jose CA, 95112      Tel: 408-287-7871     Fax:408-287-8838</div>
                            </div>
                        </div>
                </div>
    </div>
</template>
<script>
export default {
    name: "Print",
    props:['printModel'],
    data() {
      return {
        username: ""
      };
    },

    methods: {
      
    }
  };
</script>

<style scoped>

</style>